* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
}

.btn-wide {
  margin: 1rem 0;
  width: 100%;
  font-size: 3rem;
  padding: 1rem;
}

.btn-menu {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 3rem;
  /* padding: 1rem; */
  border-color: white;
  border-radius: 5px;
}

.div-centered {
  float: none;
  margin: 0 auto;
  text-align: center;
}

.cameraButton {
  position: fixed;
  bottom: 0;
}
